import React from "react"
import {
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  NativeSelect,
} from "@material-ui/core"
import { Send } from "@material-ui/icons"

const formToObject = elem => {
  let output = {}
  if (!elem) return output

  let form = new FormData(elem)
  let entries = Array.from(form.entries())
  entries.forEach(pair => {
    let key = pair[0]
    let value = pair[1]

    if (Object.prototype.hasOwnProperty.call(output, key)) {
      let current = output[key]
      if (!Array.isArray(current)) current = output[key] = [current]

      current.push(value)
    } else {
      output[key] = value
    }
  })

  return output
}

const BasketForm = props => {
  const { contact } = props

  const handleSubmit = event => {
    event.preventDefault()
    const contact = formToObject(event.target)
    props.onSubmit(contact)
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel htmlFor="payment">Način plaćanja</InputLabel>
            <NativeSelect
              defaultValue={1}
              inputProps={{
                name: "payment",
                id: "payment",
              }}
            >
              <option value="Dostava-uplata">
                Plaćanje po ponudi (dostava)
              </option>
              <option value="Dostava-pouzeće">
                Plaćanje pouzećem (gotovina + dostava)
              </option>
              <option value="Poslovnica-preuzimanje">
                Plaćanje i preuzimanje u poslovnici
              </option>
              <option value="Uplata-poslovnica">
                Plaćanje po ponudi, preuzimanje u poslovnici
              </option>
            </NativeSelect>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="firstName"
            name="firstName"
            label="Ime"
            fullWidth
            autoComplete="given-name"
            defaultValue={contact.firstName}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            name="lastName"
            label="Prezime"
            fullWidth
            autoComplete="family-name"
            defaultValue={contact.lastName}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            name="email"
            type="email"
            label="Email"
            fullWidth
            autoComplete="billing email"
            defaultValue={contact.email}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="tel"
            label="Kontakt broj *"
            fullWidth
            autoComplete="billing tel"
            defaultValue={contact.tel}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            name="address"
            label="Adresa"
            fullWidth
            autoComplete="billing address-line1"
            defaultValue={contact.address}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            name="city"
            label="Mjesto"
            fullWidth
            autoComplete="billing address-level2"
            defaultValue={contact.city}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            name="zip"
            label="Poštanski broj"
            fullWidth
            autoComplete="billing postal-code"
            defaultValue={contact.zip}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="note"
            label="Napomena"
            placeholder="Podaci firme za R1 račune, veličina okvira kod zahtjeva za biciklom..."
            fullWidth
            multiline
            rows={2}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            disabled={props.loading}
          >
            Pošalji&nbsp;
            <Send fontSize="large" />
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default BasketForm

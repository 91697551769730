import React, { useContext, useState } from "react"
import { graphql } from "gatsby"
import {
  Typography,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "@material-ui/core"
import { ExpandMore } from "@material-ui/icons"
import BasketContext from "../contexts/basket"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BasketForm from "../components/basket-form"
import BasketItem from "../components/basket-item"

const useStyles = makeStyles(theme => ({
  paper: {
    alignContent: "center",
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(700 + theme.spacing(2) * 2)]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(700 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
    boxShadow: "0px 0px 0px rgba(0,0,0,.2)",
    borderRadius: "5px",
    fontSize: "20px",
  },
  Container: {
    justifyContent: "center",
  },
  tableL: {
    color: "#fff",
    backgroundColor: "#252525",
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    fontSize: "20px",
  },
  tableR: {
    color: "#fff",
    backgroundColor: "#252525",
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    fontSize: "20px",
  },
  typo: {
    boxShadow: "0px 0px 5px rgba(0,0,0,.5)",
    borderRadius: "5px",
    marginTop: theme.spacing(1),
  },
  media: {
    position: "relative",
    height: "600px",
  },
  senttypo: {
    color: "#000",
  },
}))

const Kosarica = props => {
  const classes = useStyles()

  const [panel, setPanel] = useState("items")
  const showItems = (e, isExpanded) => setPanel(isExpanded ? "items" : false)
  const showContact = (e, isExpanded) =>
    setPanel(isExpanded ? "contact" : false)

  const [submitted, setSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleSubmit = contact => {
    basket.updateContact(contact)
    setLoading(true)
    const request = {
      body: JSON.stringify({ contact: contact, items: basket.items }),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }

    //fetch("https://localhost:5001/RequestOffer", request)
    fetch(props.data.site.siteMetadata.offerApi, request)
      .then(r => {
        basket.clear()
        setSubmitted(true)
        setLoading(false)
      })
      .catch(e => {
        setLoading(false)
      })
  }
  const basket = useContext(BasketContext)

  if (submitted)
    return (
      <Layout location={props.location} title={"Košarica"}>
        <SEO title="Košarica" />
        <Grid
          md={12}
          style={{ height: "12vh", background: "#000", width: "100%" }}
        />
        <Paper className={classes.paper}>
          <Typography
            className={classes.senttypo}
            variant="h4"
            component="h1"
            align="center"
            gutterBottom
          >
            Poslano! Zahtjeve obrađujemo po redoslijedu zaprimanja.
          </Typography>
          <Typography
            className={classes.senttypo}
            variant="h4"
            component="h1"
            align="center"
            gutterBottom
          >
            Uskoro će Vas kontaktirati naš djelatnik.
          </Typography>
        </Paper>
      </Layout>
    )

  if (basket.items.length < 1)
    return (
      <Layout location={props.location} title={"Košarica"}>
        <SEO title="Košarica" />
        <Grid
          md={12}
          style={{ height: "12vh", background: "#000", width: "100%" }}
        />
        <Paper className={classes.paper}>
          <Typography
            variant="h4"
            component="h1"
            align="center"
            color="primary"
            gutterBottom
          >
            Košarica je prazna...
          </Typography>
          <Typography
            variant="h4"
            component="h1"
            align="center"
            color="primary"
            gutterBottom
          >
            <span
              role="img"
              aria-label="Tužan emotikon"
              style={{ fontSize: "200px" }}
            >
              🥺
            </span>
          </Typography>
        </Paper>
      </Layout>
    )

  return (
    <Layout location={props.location} title={"Košarica"}>
      <SEO title="Košarica" />
      <Grid
        md={12}
        style={{ height: "12vh", background: "#000", width: "100%" }}
      />
      <div className={classes.paper}>
        <ExpansionPanel
          className={classes.typo}
          expanded={panel === "items"}
          onChange={showItems}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMore />}>
            <Typography variant="h5" component="h2" align="center" gutterBottom>
              <span role="img" aria-label="Košarica ikona">
                🛒
              </span>
              Košarica
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container spacing={2} className={classes.Container}>
              {basket.items.map(row => (
                <BasketItem
                  key={row.slug}
                  item={row}
                  onIncrement={basket.addItem}
                  onDecrement={basket.removeItem}
                />
              ))}
              <Grid item sm={12}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        rowSpan={2}
                        colSpan={2}
                        align="center"
                        className={classes.tableL}
                      >
                        <span role="img" aria-label="Ukupno emotikon">
                          🧾
                        </span>
                        Ukupan iznos:
                      </TableCell>
                      <TableCell className={classes.tableR} align="center">
                        {basket.items
                          .reduce(
                            (sum, c) =>
                              sum +
                              ((100 - c.extraDiscount - c.specialDeal) / 100) *
                                c.price *
                                c.quantity,
                            0
                          )
                          .toLocaleString("hr-HR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        &nbsp;€
                      </TableCell>
                    </TableRow>
                    {/* <TableRow>
                      <TableCell align="center" className={classes.tableR}>
                        S dodatnim popustom:&nbsp;
                      {basket.items
                          .reduce((sum, c) => sum + ((100 - c.extraDiscount) / 100) * c.price * c.quantity, 0)
                          .toLocaleString("hr-HR", {
                            minimumFractionDigits: 2, maximumFractionDigits: 2,
                          })}
                        &nbsp;€&nbsp;|&nbsp; 
                        {basket.items
                          .reduce((sum, c) => sum + ((100 - c.extraDiscount) / 100) * c.price * 7.53450 *c.quantity, 0)
                          .toLocaleString("hr-HR", {
                            minimumFractionDigits: 2, maximumFractionDigits: 2,
                          })}&nbsp;kn
                      </TableCell>
                    </TableRow> */}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          className={classes.typo}
          expanded={panel === "contact"}
          onChange={showContact}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMore />}>
            <Typography variant="h5" component="h2" align="center" gutterBottom>
              <span role="img" aria-label="Povećalo ikonica">
                🔍
              </span>
              Zatraži ponudu
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <BasketForm
              onSubmit={handleSubmit}
              contact={basket.contact}
              loading={loading}
            />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    </Layout>
  )
}

export default Kosarica

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        offerApi
      }
    }
  }
`
